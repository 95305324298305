import React from 'react'
import { Link } from 'gatsby'

class Cta extends React.Component {

    render() {
        return (
            <div>
                <div className="cta-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <h2>Zaujaly vás moje služby?</h2>
                            </div>
                            <div className="col-lg-4">
                                <div className="main-white-button">
                                    <Link to="/kontakt" data-tracking-label="CTABanner_ContactUs">Pošlete mi dotaz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cta
